import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import { ThumbnailImage } from "../components/thumbnailImage"
import "../styles/blog-list-thumbnails.scss"

export const BlogListThumbnails = () => {
    const data = useStaticQuery(graphql`
        query blogListThumbnailsQuery {
            allPrismicBlogPost {
                edges {
                node {
                    dataRaw
                }
                }
            }
        }
    `)
    const posts = data.allPrismicBlogPost.edges

    return (
        <ul className="grid c-blog-list-thumbnails">
            {posts && posts.map((post, i) => {
                const postData = post.node.dataRaw
                return (
                    <li key={i} className="col-6_sm-12"> 
                        <Link to={`/blog/${postData.slug}`}>
                            <ThumbnailImage image={postData.cover_image} />
                            {postData.title}
                        </Link>
                    </li>
                )
            })}
        </ul>
    )
}