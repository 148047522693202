import React from "react"
import Helmet from "react-helmet"
import '../styles/blog-post.scss'
import { BlogListThumbnails } from "../components/blogListThumbnails"
import { Layout } from '../layout/layout'

export default function BlogPost() {
    return (
        <Layout>
            <Helmet>
                <title>Kursori blog</title>
                <meta name="description" content="Kursori is a nature lover from Finland, who likes to explore new places." />
            </Helmet>
            <div className="t-blog-post">
                <div className="t-blog-post__container">
                    <h1>Blog posts</h1>
                    <p>Hi I'm Kursori. I like to wander around in the nature and other places, observing and documenting what I see. I like to write about my trips, outdooring in general as well as gear that I use when wandering around.</p>
                    <BlogListThumbnails />
                </div>
            </div>
        </Layout>
    )
}